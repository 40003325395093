<div class="mpr-tabs-container">
  <mat-tab-group
    animationDuration="0ms"
    class="workspace-wrapper-container"
    (selectedTabChange)="selectedTabChange($event)"
  >
    <mat-tab label="ADVANCED DEVELOPER TOOLS" id="ec2Workspace"
      ><app-ec2-workspace
        [inputs]="inputs"
        [selectedTabIndex]="selectedTabIndex"
      ></app-ec2-workspace
    ></mat-tab>
    <mat-tab
      label="AMAZON WORKSPACE"
      id="amazonWorkspace"
      *ngIf="isDeveloperWorkspaceEnabled"
      ><app-aws-workspace
        [inputs]="inputs"
        [selectedTabIndex]="selectedTabIndex"
      ></app-aws-workspace
    ></mat-tab>
  </mat-tab-group>
</div>
