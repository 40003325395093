<ng-container
  *ngIf="{
    connections: (connectionsLoading$ | async),
    connection: (connectionLoading$ | async),
    projectSurveyLoader : (projectSurveyLoader$ | async)
  } as loaders"
>
  <div class="mpr-onetime-upload-form" *ngIf= "!loaders.connections && (isForstaFeatureFlagEnabled && !loaders.projectSurveyLoader); else showPageLoader">
    <!-- dropdown and browse row : Starts-->
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div>
        <div class="mpr-data-source-label">
          Select Data Source<sup>*</sup>
          <button class="mpr-btn-transparency" 
            [mtxTooltip]="dataSourePopover" 
            [mprMtxTooltipAriaDescribedBy]="dataSourePopover" 
            #tooltip="mtxTooltip"
            aria-label="Data Source Information">
            <mat-icon
              class="mpr-info-icon"
              >info
            </mat-icon>
          </button>  
        </div>

        <mat-form-field
          class="mpr-mat-select-field mpr-select-field-width"
          
          appearance="outline"
          aria-label="select data source"
        >
          <mat-select
            disableOptionCentering
            #typeOfDataSource
            (selectionChange)="
              showConfirmOnChangeIfApplicable(typeOfDataSource.value)
            "
            panelClass="mpr-select-option-panel mpr-mat-arrow"
            [(value)]="defaultValue"
            placeholder="Select Data Source"
          >
            <mat-option [value]="localConnection.connectionId">{{localConnection.connectionName}}</mat-option>
            <mat-option [value]="dL_S3Connection.connectionId">{{dL_S3Connection.connectionName}}</mat-option>
            <mat-option [value]="forstaConnection.connectionId" *ngIf="showForstaInDropdown">{{forstaConnection.connectionName}}</mat-option>
            <mat-option
              *ngFor="let connection of connectionList"
              [value]="connection.connectionId"
            >
              {{ connection.connectionName }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div
        class="flex-item m-l-12 m-r-12"
        fxFlex
        [fxLayout]="loaders.connection ? 'row-reverse' : 'row'"
        *ngIf="loaders.connection || loaders.connections"
      >
        <mat-spinner diameter="30" color="accent"></mat-spinner>
      </div>
    </div>
    <!-- dropdown and browse row : Ends-->

    <!-- messaging div : Starts -->
    <div fxLayout="row" fxLayoutAlign="space-between center " *ngIf="selectedConnection.connectionId !== 'forsta-sftp'">
      <div class="mpr-data-source-label">
        <span>Add New Files</span>
        <div class="select-file-dropdrop-info">
          NOTE: The file extensions: [{{ unsupportedFileTypes.join(', ') }}] are
          not supported by {{ 'appName' | appDetails }}
        </div>
      </div>
      <div>
        <label for="addFiles" class="file-input-native">Add New Files</label
        ><input
          id="addFiles"
          type="file"
          class="file-input-native"
          multiple
          (change)="getFiles($event)"
          #fileUpload
        />
        <button
          mat-stroked-button
          [disabled]="loaders.connection || typeOfDataSource.value === ''"
          class="mpr-button mpr-green-button"
          (click)="showReplaceWarningOrFilePciker(selectFileTypeEnum.FILES)"
        >
          Add File(S)
        </button>
      </div>
    </div>
    <!-- messaging div : Ends -->

    <!-- file table : Starts-->
    <div class="mpr-list-files" *ngIf="selectedConnection.dataStore !== 'forsta-sftp'">
      <div class="mpr-no-files-text" *ngIf="!fileList.length">
        No files are uploaded yet
      </div>
      <div class="mpr-file-list-table" *ngIf="fileList.length">
        <mpr-selected-file-table
          [dataSource]="fileList"
          (removeFiles)="removeFilesFromList($event, 'Files')"
        >
        </mpr-selected-file-table>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="selectedConnection.dataStore === 'forsta-sftp'">
      <div>
        <div class="mpr-data-source-label">
          Select Survey<sup>*</sup>
          <button class="mpr-btn-transparency" 
            [mtxTooltip]="surveyPopover" 
            [mprMtxTooltipAriaDescribedBy]="surveyPopover" 
            #tooltip="mtxTooltip"
            aria-label="Select Survey Information">
            <mat-icon
              class="mpr-info-icon"
              >info
            </mat-icon>
          </button>  
        </div>

        <mat-form-field
          class="mpr-mat-select-field mpr-select-survey-field"
          
          appearance="outline"
          aria-label="select survey"
        >
          <mat-select
          disableOptionCentering
          placeholder="Select Survey"
          [(ngModel)]="surveyId">
            <mat-option
              *ngFor="let survey of projectSurveys"
              [value]="survey.surveyId"
            >{{survey.surveyId}} - {{survey.surveyName}}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <!-- file table : Ends-->
    <!-- dropdown and browse row : Starts-->
    <div fxLayout="row" fxLayoutAlign="space-between center"> 
      <div>
        <label class="mpr-data-source-label" for ="selectDestinationFolder">
          Select Destination Folder
          <button class="mpr-btn-transparency" 
            [mtxTooltip]="destinationFolderPopover" 
            [mprMtxTooltipAriaDescribedBy]="destinationFolderPopover" 
            #tooltip="mtxTooltip"
            aria-label="Destination Folder Information"
          >
            <mat-icon
              class="mpr-info-icon"
              >info
            </mat-icon>
          </button>  
        </label>

        <div fxLayoutAlign="space-between center">
          <div>
            <input
              id="selectDestinationFolder"
              class="mpr-input-field-width"
              #destinationFolder
              formControlName="destinationFolder"
              [value]="displayDestinationPath"
              [readonly]="true"
            />
          </div>
          <div>
            <button
              mat-stroked-button
              class="mpr-button mpr-green-button mpr-margin-left"
              (click)="showFolderPickerDialog()"
            >
              SELECT FOLDER
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- dropdown and browse row : Ends-->
    <div [fxLayoutAlign]="'flex-end'" class="mpr-margin-top">
      <div>
        <!--cancel and preceed row: Starts-->
        <div class="mpr-buttons-row" fxLayout="row" fxLayoutAlign="flex-end">
          <div>
            <button
              mat-stroked-button
              class="mpr-button mpr-blue-button mpr-cancel-button"
              (click)="navigateBack()"
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              [disabled]="(selectedConnection.dataStore === 'forsta-sftp' && !surveyId) || (selectedConnection.dataStore !== 'forsta-sftp' && fileList.length === 0) ||
              displayDestinationPath === ''"
              mat-button
              class="mpr-button mpr-yellow-button"
              (click)="selectedConnection.dataStore === 'forsta-sftp' ? startFileUpload() : checkObjectExists()"
            >
              Upload
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--cancel and preceed row: Ends-->
  </div>
</ng-container>

<ng-template #dataSourePopover>
  <div class="mpr-width-px-300" id="mpr-data-source">
    Select Data Source from where you want to upload the file.
  </div>
</ng-template>
<ng-template #surveyPopover>
  <div class="mpr-width-px-300" id="mpr-select-survey">
    The Forsta project ID (PID) that is used to identify the survey in Forsta.
  </div>
</ng-template>
<ng-template #destinationFolderPopover>
  <div class="mpr-width-px-160" id="mpr-select-folder">Select destination folder</div>
</ng-template>

<ng-template #DuplicateFileMsg>
  <div>
    {{ duplicateFileList.length }} file(s) with the same names already exist. So
    we couldn't upload them. <br />
    You can keep a copy of them in the destination folder or cancel.
  </div>
  <div class="mpr-duplicate">
    <ol class="mpr-duplicate-files">
      <li *ngFor="let fileName of duplicateFileList">{{ fileName }}</li>
    </ol>
  </div>
</ng-template>

<ng-template #showPageLoader>
  <div class="mpr-content-loader">
      <h1 class="mpr-content-loader-text">Loading...</h1>
  </div>
</ng-template>