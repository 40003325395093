<div class="mpr-manage-rds" *ngIf="rdsClusterState$ | async as rdsClusterState">
  <ng-container
    *ngIf="
      (fetchRDSInProgress$ | async) === false &&
        (fetchProjectsMetadataInProgress$ | async) === false;
      else loader
    "
  >
    <ng-container *ngIf="showRDS">
      <ng-container *ngIf="!isPlatformAdmin">
        <div class="field-container">
          <div class="field-label mpr-project-name">
            LIST OF RDS CLUSTERS
            <button
              mat-button
              #skipper
              class="mpr-button mpr-list-rds-refresh-button"
              *ngIf="
                rdsClusterState.rdsClusters.length !== 0 &&
                viewOnlyForProjectAdminAndDeveloper()
              "
              (click)="doRefresh()"
            >
              <span class="mpr-refresh-img">
                <img src="assets/images/New_Refresh.svg" alt="" />
              </span>
              <span class="mpr-refresh"> Refresh </span>
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isPlatformAdmin">
        <div class="field-container">
          <div class="field-label mpr-project-name">
            PROJECT : {{ projectId }}
          </div>
        </div>
        <div class="mpr-manage-rds-top-box">
          <div class="mpr-manage-rds-top-box-content">
            Click <strong>SET UP RDS CLUSTER</strong> to create a new RDS
            cluster for this project.
          </div>
          <div fxLayout="row">
            <button
              id="btnSetupUserConnection"
              mat-button
              class="mpr-button mpr-yellow-button mat-button mat-focus-indicator"
              #skipper
              (click)="setupNewRDSCluster()"
              [disabled]="disableAllButtons"
            >
              SET UP RDS CLUSTER
            </button>
            <button
              mat-button
              class="mpr-button mpr-aws-workspace-second-btn"
              *ngIf="
                rdsClusterState.rdsClusters.length !== 0 || showInprogressSetup
              "
              (click)="doRefresh()"
              [disabled]="disableAllButtons"
            >
              <span class="mpr-refresh-img">
                <img src="assets/images/New_Refresh.svg" alt="" />
              </span>
              <span class="mpr-refresh"> Refresh </span>
            </button>
          </div>
        </div>
      </ng-container>
      <div
        class="mpr-manage-rds-filler"
        id="setupUserConnection"
        *ngIf="rdsClusterState.rdsClusters.length === 0 && isPlatformAdmin"
      >
        <div class="use-connect-aws-account-image">
          <img src="./assets/images/workspace-man-with-computer.png" alt="" />
        </div>
        <div class="mpr-manage-rds-filler-content">
          <div class="mpr-manage-rds-filler-heading">SET UP RDS TO:</div>
          <div class="mpr-manage-rds-filler-list">
            <ul>
              <li>
                Store and manage structured data in a tabular relational format.
              </li>
              <li>
                Enforce data types using schemas for greater data consistency.
              </li>
              <li>
                Query and process data using SQL (structured query language).
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div *ngIf="rdsClusterState.rdsClusters.length === 0 && !isPlatformAdmin">
        <div class="mpr-dataconnector-table-noresult">
          <div class="mpr-noresult-container">
            <div class="mpr-noresutl-error">
              <span><img src="assets/images/Error.svg" alt="Error" /> </span>
              <span> No RDS clusters are created for this project.</span>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="rdsClusterState.rdsClusters.length > 0">
        <mat-table
          [dataSource]="rdsClusterDataSource"
          class="mpr-tables mpr-cross-accounts-table"
        >
          <ng-container matColumnDef="clusterName">
            <mat-header-cell *matHeaderCellDef>Cluster Name</mat-header-cell>
            <mat-cell *matCellDef="let element">{{
              element.clusterName
            }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="dbEngine">
            <mat-header-cell *matHeaderCellDef>DB Engine</mat-header-cell>
            <mat-cell *matCellDef="let element">{{
              element.dbEngine ? element.dbEngine : 'NA'
            }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="dbInstanceCount">
            <mat-header-cell *matHeaderCellDef>Instances</mat-header-cell>
            <mat-cell *matCellDef="let element">{{
              element.dbInstanceCount ? element.dbInstanceCount : 'NA'
            }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="dateClusterSetup">
            <mat-header-cell *matHeaderCellDef
              >Date Cluster Set Up
              <span
                class="mpr-sort-image mpr-descending-icon"
                role="img"
                aria-label="descending"
              ></span>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">{{
              element.dateClusterSetup
                ? (element.dateClusterSetup | utcDate: 'MM/dd/yyyy')
                : 'NA'
            }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
            <mat-cell *matCellDef="let element"
              ><span [ngClass]="element.displayStatus">{{
                element.displayStatus
              }}</span></mat-cell
            >
          </ng-container>
          <ng-container matColumnDef="actionMenu">
            <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
            <mat-cell *matCellDef="let rdscluster">
              <button
                aria-label="action"
                class="mpr-button-style mpr-btn-transparency mpr-cursor-pointer"
                [matMenuTriggerFor]="menu"
                mat-menu-trigger
                #trigger="matMenuTrigger"
              >
                <img
                  src="assets/images/More.svg"
                  alt="Action Menu"
                  class="imgClass imgFolder mpr-cursor-pointer mpr-more-icon"
                />
              </button>
              <mat-menu #menu="matMenu" class="mpr-mat-menu-custom-view">
                <button
                  mat-menu-item
                  class="mpr-cursor-pointer"
                  *ngIf="displayViewDetails()"
                  (click)="openRDSClusterDetailsDialog(rdscluster)"
                >
                  <img
                    src="assets/images/preview.svg"
                    alt=""
                    class="imgClass small-imgTooltip mpr-export"
                  />
                  <span>Connection details</span>
                </button>
                <button
                  mat-menu-item
                  class="mpr-cursor-pointer"
                  (click)="redirectToClusterUserList(rdscluster)"
                  *ngIf="displayManageUsers(rdscluster)"
                >
                  <img
                    src="assets/images/group.svg"
                    alt=""
                    class="imgClass small-imgTooltip mpr-export"
                  />
                  <span>Manage users</span>
                </button>

                <button
                  mat-menu-item
                  class="mpr-cursor-pointer"
                  *ngIf="displayCreateProxy(rdscluster)"
                  (click)="openCreateProxyEndpointDialog(rdscluster)"
                  [disabled]="rdscluster.displayStatus !== 'Running'"
                >
                  <img
                    src="assets/images/preview.svg"
                    alt=""
                    class="imgClass small-imgTooltip mpr-export"
                  />
                  <span>Create proxy endpoint</span>
                </button>
                <button
                  mat-menu-item
                  class="mpr-cursor-pointer"
                  *ngIf="displayEditCluster()"
                  [disabled]="disableRename(rdscluster.displayStatus)"
                  (click)="showRenameRDSCluster(rdscluster)"
                >
                  <img
                    src="assets/images/Rename.svg"
                    alt=""
                    class="imgClass imgTooltip mpr-export"
                  />
                  <span>Rename cluster</span>
                </button>

                <button
                  mat-menu-item
                  class="mpr-cursor-pointer"
                  [class.disabled]="!enableActionTrigger(rdscluster)"
                  *ngIf="displayStopCluster(rdscluster)"
                  (click)="triggerActionStartOrStop(rdscluster, 'stop')"
                >
                  <img
                    src="assets/images/stop_circle.svg"
                    alt=""
                    class="imgClass small-imgTooltip mpr-export"
                  />
                  <span>Stop cluster</span>
                </button>
                <button
                  mat-menu-item
                  class="mpr-cursor-pointer"
                  [class.disabled]="!enableActionTrigger(rdscluster)"
                  *ngIf="displayStartCluster(rdscluster)"
                  (click)="triggerActionStartOrStop(rdscluster, 'start')"
                >
                  <img
                    src="assets/images/start_circle.svg"
                    alt=""
                    class="imgClass small-imgTooltip mpr-export"
                  />
                  <span>Start cluster</span>
                </button>
                <button
                  mat-menu-item
                  class="mpr-cursor-pointer"
                  *ngIf="displayDeleteCluster()"
                  [disabled]="
                    rdscluster.displayStatus !== 'Stopped' &&
                    rdscluster.displayStatus !== 'Failed'
                  "
                  (click)="showDeleteClusterDialog(rdscluster)"
                >
                  <img
                    src="assets/images/delete.svg"
                    alt=""
                    class="imgClass imgTooltip mpr-export"
                  />
                  <span>Delete cluster</span>
                </button>
              </mat-menu>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="details">
            <mat-header-cell *matHeaderCellDef>Details</mat-header-cell>
            <mat-cell *matCellDef="let rdscluster">
              <button
                #skipper
                class="mpr-cursor-pointer mpr-link mpr-btn-transparency"
                (click)="openRDSClusterDetailsDialog(rdscluster)"
                title="View Details"
                aria-label="View Details"
              >
                View
              </button>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
      </div>
    </ng-container>
    <div *ngIf="!showRDS">
      <div class="mpr-dataconnector-table-noresult">
        <div class="mpr-noresult-container">
          <div class="mpr-noresutl-error">
            <span><img src="assets/images/Error.svg" alt="Error" /> </span>
            <span> RDS feature is not enabled for this project</span>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #showRDSClusterDetailsDialog let-data>
  <div class="mpr-dialog-close" (click)="closeViewDetailsDialog()">
    <img src="assets/images/cancel-popup.svg" alt="Close icon" />
  </div>
  <h1 mat-dialog-title class="mpr-dialog-title-rds-details">
    RDS Connection Details
  </h1>
  <mat-tab-group class="mat-tab-group workspace-wrapper-container mat-primary">
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="mpr-bold-label">CONNECT FROM MQUIRY</span>
      </ng-template>
      <div class="mpr-mat-dialog-small">
        <ng-container *ngIf="!data.rdsCluster?.dbUserName">
          <div class="d-flex mpr-now-aws-accounts-warning">
            <img
              class="mpr-warning-icon"
              src="assets/images/warning-icon-red-triangle.svg"
              alt="Warning"
            /><span
              >You do not have access to this RDS cluster. Please contact your
              project lead.</span
            >
          </div>
        </ng-container>
        <ng-container *ngIf="data.rdsCluster?.dbUserName">
          <div class="note-content-box">
            <b>
              Use the details below to connect to RDS. Refer to the
              <a class="mpr-help-url" href="{{ rdsHelpURL }}" target="_blank"
                ><span>user guide</span>
                <img
                  class="mpr-new-window-icon"
                  src="assets/images/new-window.svg "
                  alt=""
                />
              </a>
              for more info.<br />
              <div class="mpr-warning-text" *ngIf="viewOnlyForProjectAdmin()">
                <img
                  class="mpr-red-warning-icon"
                  src="assets/images/warning-icon-red-triangle.svg"
                  alt=" EC2 provision failed warning"
                />
                Use your {{ 'appName' | appDetails }} role or a least-privileged
                service account whenever possible. Use the RDS master user only
                for required administrative tasks.
              </div>
            </b>
          </div>
          <div mat-dialog-content class="content-of-popup">
            <div class="form-group" *ngIf="data.rdsCluster">
              <div class="mpr-form-block-field m-t-4">
                <div class="mpr-form-block-field-label text-uppercase">
                  Host Name
                </div>
                <div class="mpr-rds-details" fxLayout="row">
                  <span class="mpr-rds-details-value">{{
                    data.rdsCluster.endpoint
                  }}</span>
                  <span class="mpr-private-space-copy">
                    <button
                      class="mpr-icon-button mpr-copy-to-clipboard-icon"
                      (click)="copyToClipBoard(data.rdsCluster?.endpoint)"
                      matTooltip="Copy Host Name"
                      aria-label="Copy Host Name"
                      id="rdsHostName"
                    ></button>
                  </span>
                </div>
              </div>
              <div class="mpr-form-block-field m-t-4">
                <div class="mpr-form-block-field-label text-uppercase">
                  Port
                </div>
                <div class="mpr-rds-details" fxLayout="row">
                  <span class="mpr-rds-details-value">{{
                    data.rdsCluster.port
                  }}</span>
                  <span class="mpr-private-space-copy">
                    <button
                      class="mpr-icon-button mpr-copy-to-clipboard-icon"
                      (click)="copyToClipBoard(data.rdsCluster?.port)"
                      matTooltip="Copy Port"
                      aria-label="Copy Port"
                      id="rdsPort"
                    ></button>
                  </span>
                </div>
              </div>
              <div class="mpr-form-block-field m-t-4">
                <div class="mpr-form-block-field-label text-uppercase">
                  DB User Name
                </div>
                <div class="mpr-rds-details" fxLayout="row">
                  <span class="mpr-rds-details-value">{{
                    data.rdsCluster.dbUserName
                  }}</span>
                  <span class="mpr-private-space-copy">
                    <button
                      class="mpr-icon-button mpr-copy-to-clipboard-icon"
                      (click)="copyToClipBoard(data.rdsCluster.dbUserName)"
                      [class.disabled]="!isDBUserAdded(data.rdsCluster)"
                      matTooltip="Copy DB User Name"
                      aria-label="Copy DB User Name"
                      id="rdsDBUserName"
                    ></button>
                  </span>
                </div>
              </div>
              <!--- Section for RDS TOKEN -->
              <div
                class="mp-generate-rds-token-header"
                *ngIf="isDBUserAdded(data.rdsCluster)"
              >
                <div class="d-flex">
                  <div class="mpr-form-block-field-label text-uppercase">
                    RDS Access Token
                  </div>
                  <div>
                    <button
                      class="mpr-btn-transparency"
                      [mtxTooltip]="rdsTokenInfo"
                      [mprMtxTooltipAriaDescribedBy]="rdsTokenInfo"
                      #tooltip="mtxTooltip"
                      type="button"
                      aria-label="This token expires in 15 mins. However, session created using this token will remain active beyond that."
                    >
                      <mat-icon class="mpr-info-icon mpr-copy-arn-info-icon"
                        >info</mat-icon
                      >
                    </button>
                  </div>
                </div>
                <ng-container
                  *ngIf="{
                    isTokenGenerationInProgress:
                      (isTokenGenerationInProgress$ | async),
                    rdsTokenResponse: (rdsClusterTokenResponse$ | async)
                  } as tokenData"
                >
                  <div *ngIf="tokenData.isTokenGenerationInProgress">
                    <mat-spinner diameter="25" color="accent"></mat-spinner>
                  </div>
                  <div
                    fxLayout="row"
                    *ngIf="
                      tokenData.rdsTokenResponse?.accessToken;
                      else generateButton
                    "
                  >
                    <span
                      class="mpr-rds-details-value mpr-overflow-auto"
                      tabindex="0"
                    >
                      {{ tokenData.rdsTokenResponse?.accessToken }}
                    </span>
                    <span class="mpr-private-space-copy">
                      <button
                        class="mpr-icon-button mpr-copy-to-clipboard-icon"
                        [mprCopyToClipboard]="
                          tokenData.rdsTokenResponse
                            ? tokenData.rdsTokenResponse.accessToken
                            : ''
                        "
                        matTooltip="Copy Token"
                        aria-label="Copy Token"
                        id="rdsToken"
                      ></button>
                    </span>
                  </div>
                  <ng-template #generateButton>
                    <div
                      class="mpr-generate-rds-token-link"
                      *ngIf="!tokenData.isTokenGenerationInProgress"
                    >
                      <button
                        id="generateRDSClusterTokenId"
                        (click)="generateRDSClusterToken(data.rdsCluster)"
                        class="mpr-btn-transparency mpr-button-link mpr-cursor-pointer mpr-skipper p-l-0 mpr-underline"
                      >
                        Generate
                      </button>
                    </div>
                  </ng-template>
                </ng-container>
              </div>
              <!-- Display Master UserName and Password only for admin -->
              <div *ngIf="viewOnlyForProjectAdmin()">
                <div class="divider-line"></div>
                <div class="mpr-form-block-field m-t-4">
                  <div class="mpr-form-block-field-label text-uppercase">
                    Master User Name
                  </div>
                  <div class="mpr-rds-details" fxLayout="row">
                    <div class="mpr-rds-details-value">
                      <span>{{
                        isUsernameVisible
                          ? data.rdsCluster?.username
                          : '&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;'
                      }}</span>
                      <button
                        class="mpr-eye-icon mpr-btn-transparency"
                        (click)="toggleUsernameVisibility()"
                        aria-label="Toggle Username visisbility"
                      >
                        <mat-icon>{{
                          isUsernameVisible ? 'visibility_off' : 'visibility'
                        }}</mat-icon>
                      </button>
                    </div>
                    <span class="mpr-private-space-copy">
                      <button
                        class="mpr-icon-button mpr-copy-to-clipboard-icon"
                        (click)="copyToClipBoard(data.rdsCluster?.username)"
                        matTooltip="Copy Username"
                        aria-label="Copy Username"
                        id="rdsUsername"
                      ></button>
                    </span>
                  </div>
                </div>
                <div class="mpr-form-block-field m-t-4">
                  <div class="mpr-form-block-field-label text-uppercase">
                    Password
                  </div>
                  <div class="mpr-rds-details" fxLayout="row">
                    <div class="mpr-rds-details-value">
                      <span>{{
                        isPasswordVisible
                          ? data.rdsCluster?.password
                          : '&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;'
                      }}</span>
                      <button
                        class="mpr-eye-icon mpr-btn-transparency"
                        (click)="togglePasswordVisibility()"
                        aria-label="Toggle Password visisbility"
                      >
                        <mat-icon>{{
                          isPasswordVisible ? 'visibility_off' : 'visibility'
                        }}</mat-icon>
                      </button>
                    </div>
                    <span class="mpr-private-space-copy">
                      <button
                        class="mpr-icon-button mpr-copy-to-clipboard-icon"
                        (click)="copyToClipBoard(data.rdsCluster?.password)"
                        matTooltip="Copy Password"
                        aria-label="Copy Password"
                        id="rdsPassword"
                      ></button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </mat-tab>
    <mat-tab *ngIf="displayCreateProxy(data.rdscluster, false)">
      <ng-template mat-tab-label>
        <span class="mpr-bold-label">CONNECT FROM PROJECT AWS ACCOUNT</span>
      </ng-template>
      <ng-container *ngIf="data.enabledCrossAccounts.length === 0">
        <div class="mpr-mat-dialog-small">
          <div class="d-flex mpr-now-aws-accounts-warning">
            <img
              class="mpr-warning-icon"
              src="assets/images/warning-icon-red-triangle.svg"
              alt="Warning Image"
            /><span
              >Contact
              <a
                class="mpr-service-now-link"
                href="{{ contactUs }}"
                target="_blank"
                >{{ 'appName' | appDetails }} Support</a
              >
              to configure your project's AWS accounts with
              {{ 'appName' | appDetails }} before you can create a proxy
              endpoint.</span
            >
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="data.enabledCrossAccounts.length !== 0">
        <div class="mpr-mat-dialog-small">
          <div class="note-content-box">
            <b>
              Use the details below to connect to RDS from your project AWS
              account. Refer to the
              <a
                class="mpr-help-url"
                href="{{
                  'relational-database-service-guide/rds-proxy-setup'
                    | helpLinkUrl
                }}"
                target="_blank"
                ><span>user guide</span>
                <img
                  class="mpr-new-window-icon"
                  src="assets/images/new-window.svg "
                  alt=""
                />
              </a>
              for more info.<br />
            </b>
          </div>
          <div mat-dialog-content class="content-of-popup">
            <div class="mpr-form-block-field m-t-4">
              <div class="mpr-form-block-field-label text-uppercase">
                Select AWS Account
              </div>
              <mat-form-field
                class="mpr-mat-select-field mpr-width-percentage-100 mpr-select-aws"
                appearance="outline"
              >
                <mat-select
                  id="selectAwsAccount"
                  aria-label="Select AWS Account"
                  (selectionChange)="resetgenerateProxyTokenResponse()"
                  #selectAWSAccount
                >
                  <!-- Add options here -->
                  <mat-option
                    *ngFor="let account of data.enabledCrossAccounts"
                    [value]="account.attributeId"
                  >
                    {{ account.accountId }} - {{ account.description }}
                  </mat-option>
                </mat-select>
                <mat-hint
                  class="mat-error"
                  *ngIf="
                    selectAWSAccountProxyNotCreated(
                      selectAWSAccount.value,
                      data.rdsCluster.proxyEndpoints
                    )
                  "
                  >Proxy Endpoint is not created for the selected AWS
                  account</mat-hint
                >
              </mat-form-field>
            </div>
            <div class="mpr-form-block-field m-t-4">
              <div class="mpr-form-block-field-label text-uppercase">
                RDS Proxy Endpoint
              </div>
              <div class="mpr-rds-details" fxLayout="row">
                <span class="mpr-rds-details-value">{{
                  getProxyEndpointValues(
                    selectAWSAccount.value,
                    data.rdsCluster.proxyEndpoints,
                    'endpointUrl'
                  )
                }}</span>
                <span class="mpr-private-space-copy">
                  <button
                    class="mpr-icon-button mpr-copy-to-clipboard-icon"
                    (click)="
                      copyToClipBoard(
                        getProxyEndpointValues(
                          selectAWSAccount.value,
                          data.rdsCluster.proxyEndpoints,
                          'endpointUrl'
                        )
                      )
                    "
                    [ngClass]="{
                      disabled: disableCopy(
                        selectAWSAccount.value,
                        data.rdsCluster.proxyEndpoints,
                        'endpointUrl'
                      )
                    }"
                    matTooltip="Copy RDS Proxy Endpoint"
                    aria-label="Copy RDS Proxy Endpoint"
                    id="rdsPBMEndpoint"
                  ></button>
                </span>
              </div>
            </div>

            <div class="mpr-form-block-field m-t-4">
              <div class="mpr-form-block-field-label text-uppercase">Port</div>
              <div class="mpr-rds-details" fxLayout="row">
                <span class="mpr-rds-details-value">{{
                  getProxyEndpointValues(
                    selectAWSAccount.value,
                    data.rdsCluster.proxyEndpoints,
                    'port'
                  )
                }}</span>
                <span class="mpr-private-space-copy">
                  <button
                    class="mpr-icon-button mpr-copy-to-clipboard-icon"
                    (click)="
                      copyToClipBoard(
                        getProxyEndpointValues(
                          selectAWSAccount.value,
                          data.rdsCluster.proxyEndpoints,
                          'port'
                        )
                      )
                    "
                    [ngClass]="{
                      disabled: disableCopy(
                        selectAWSAccount.value,
                        data.rdsCluster.proxyEndpoints,
                        'port'
                      )
                    }"
                    matTooltip="Copy Port"
                    aria-label="Copy Port"
                    id="rdsPort"
                  ></button>
                </span>
              </div>
            </div>
            <div class="mpr-form-block-field m-t-4">
              <div class="mpr-form-block-field-label text-uppercase">
                Service Account Database User Name
              </div>
              <div class="mpr-rds-details" fxLayout="row">
                <span class="mpr-rds-details-value">{{
                  getProxyEndpointValues(
                    selectAWSAccount.value,
                    data.rdsCluster.proxyEndpoints,
                    'endpointUrl'
                  ) !== 'NA'
                    ? data.rdsCluster?.proxyServiceAccountUser || 'NA'
                    : 'NA'
                }}</span>
                <span class="mpr-private-space-copy">
                  <button
                    class="mpr-icon-button mpr-copy-to-clipboard-icon"
                    (click)="
                      copyToClipBoard(data.rdsCluster?.proxyServiceAccountUser)
                    "
                    [class.disabled]="!data.rdsCluster?.proxyServiceAccountUser"
                    matTooltip="Copy Service Account Database User Name"
                    aria-label="Copy Service Account Database User Name"
                    id="rdsSADBUserName"
                  ></button>
                </span>
              </div>
            </div>
            <div
              class="mp-generate-rds-token-header"
              *ngIf="
                selectAWSAccount.value &&
                !selectAWSAccountProxyNotCreated(
                  selectAWSAccount.value,
                  data.rdsCluster.proxyEndpoints
                )
              "
            >
              <div class="d-flex">
                <div class="mpr-form-block-field-label text-uppercase">
                  RDS Access Token
                </div>
                <div>
                  <button
                    class="mpr-btn-transparency"
                    [mtxTooltip]="rdsTokenInfo"
                    [mprMtxTooltipAriaDescribedBy]="rdsTokenInfo"
                    #tooltip="mtxTooltip"
                    type="button"
                    aria-label="This token expires in 15 mins. However, session created using this token will remain active beyond that."
                  >
                    <mat-icon class="mpr-info-icon mpr-copy-arn-info-icon"
                      >info</mat-icon
                    >
                  </button>
                </div>
              </div>
              <ng-container
                *ngIf="{
                  isTokenGenerationInProgress:
                    (isTokenGenerationInProgress$ | async),
                  proxyTokenResponse: (proxyTokenResponse$ | async)
                } as tokenData"
              >
                <div *ngIf="tokenData.isTokenGenerationInProgress">
                  <mat-spinner diameter="25" color="accent"></mat-spinner>
                </div>
                <div
                  fxLayout="row"
                  *ngIf="
                    tokenData.proxyTokenResponse?.accessToken;
                    else generateButton
                  "
                >
                  <span
                    class="mpr-rds-details-value mpr-overflow-auto"
                    tabindex="0"
                  >
                    {{ tokenData.proxyTokenResponse?.accessToken }}
                  </span>
                  <span class="mpr-private-space-copy">
                    <button
                      class="mpr-icon-button mpr-copy-to-clipboard-icon"
                      [mprCopyToClipboard]="
                        tokenData.proxyTokenResponse
                          ? tokenData.proxyTokenResponse.accessToken
                          : ''
                      "
                      matTooltip="Copy Token"
                      aria-label="Copy Token"
                      id="rdsToken"
                    ></button>
                  </span>
                </div>

                <ng-template #generateButton>
                  <div
                    class="mpr-generate-rds-token-link"
                    *ngIf="!tokenData.isTokenGenerationInProgress"
                  >
                    <button
                      id="generateRDSClusterTokenId"
                      (click)="
                        generateProxyToken(
                          data.rdsCluster,
                          selectAWSAccount.value
                        )
                      "
                      class="mpr-btn-transparency mpr-button-link mpr-cursor-pointer mpr-skipper p-l-0 mpr-underline"
                    >
                      Generate
                    </button>
                  </div>
                </ng-template>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </mat-tab>
  </mat-tab-group>
</ng-template>
<ng-template #showWarningDialog let-data>
  <div class="mpr-mat-dialog">
    <div class="mpr-close-icon mpr-warning-close-icon" (click)="closeDialog()">
      <img
        class="close-icon"
        src="assets/images/cancel-popup.svg"
        alt="Close icon"
      />
    </div>
    <div mat-dialog-content class="content-of-popup">
      <div class="mpr-warning">
        <img
          class="mpr-red-warning"
          src="assets/images/warning-icon-red-triangle.svg"
          alt="Warning Message"
        />
        <span>
          {{ data.message }}
        </span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loader>
  <div class="d-flex mpr-justify-content-center mpr-table-loader">
    <span>
      <mat-spinner diameter="45" color="accent"></mat-spinner>
    </span>
  </div>
</ng-template>

<ng-template #rdsTokenInfo>
  <div id="mpr-tooltip-rds-token" tabindex="0">
    This token expires in 15 minutes. However, the <br />
    RDS session remains active after establishing <br />
    a connection with this token.
  </div>
</ng-template>
