import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import {
  CreateProxyEndpoint,
  LoggedinUserState,
  ManageRDSClustersModel,
  ManageRDSClusterState,
} from 'app/state';
import { catchError, Observable, throwError, withLatestFrom } from 'rxjs';
import { AlertMessageService } from '@core/services';
import { environment } from '@env/environment';
import ExternalProjectAccountModel from 'app/state/project/external-project-account.model';
import {
  FeatureFlagsState,
  FeatureFlagsStateModel,
} from 'app/state/feature-flags';
@Component({
  selector: 'mpr-create-proxy-endpoint',
  templateUrl: './create-proxy-endpoint.component.html',
  styleUrls: ['./create-proxy-endpoint.component.scss'],
})
export class CreateProxyEndpointComponent {
  @Select(FeatureFlagsState.returnFeatureFlags)
  public featureFlags$!: Observable<FeatureFlagsStateModel>;
  @Select(ManageRDSClusterState.GetRDSClusterState)
  public rdsClusterState$!: Observable<ManageRDSClustersModel>;
  public contactUs = '';
  public createProxyEndpointForm: FormGroup;
  public crossAccountHelpURL = `${environment.helpPageNowUrl}powered-by/overview`;
  public disableConfirmShowLoader = false;
  public enabledCrossAccounts: ExternalProjectAccountModel[] = [];
  public loggedinUserEmailDomain = '';
  public showNoAWSAccountsError = false;
  constructor(
    private dialogRef: MatDialogRef<CreateProxyEndpointComponent>,
    private fb: FormBuilder,
    private store: Store,
    private alertService: AlertMessageService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.createProxyEndpointForm = this.fb.group(
      {
        clusterName: [
          { value: data.selectedCluster.clusterName, disabled: true },
        ],
        subnetIds: new FormArray([
          new FormControl('', [Validators.required]),
          new FormControl('', [Validators.required]),
        ]),
        attributeId: [
          '',
          [Validators.required, this.checkIfProxyExistsForCluster.bind(this)],
        ],
        rdsResourceId: [data.selectedCluster.rdsResourceId],
        projectId: [data.projectId],
      },
      { validator: [this.checkDuplicateSubnetId] }
    );

    this.showNoAWSAccountsError = data.externalProjectAWSAccounts.length === 0;
    this.featureFlags$.subscribe((featureFlags: FeatureFlagsStateModel) => {
      this.loggedinUserEmailDomain = this.store
        .selectSnapshot(LoggedinUserState.getLoggedInUserEmail)
        .split('@')[1];
      const isLoggedInUserInternal =
        featureFlags.internalDomainList.findIndex(
          (x) => x === this.loggedinUserEmailDomain
        ) > -1;
      this.contactUs = isLoggedInUserInternal
        ? featureFlags.internalContactUsUrl
        : featureFlags.externalContactUsUrl;
    });
    this.enabledCrossAccounts = data.externalProjectAWSAccounts
      .map((account: ExternalProjectAccountModel) => ({
        ...account,
        modifiedDate: account.modifiedDate,
      }))
      .sort(
        (
          a: { modifiedDate: string | number | Date },
          b: { modifiedDate: string | number | Date }
        ) =>
          new Date(b.modifiedDate).getTime() -
          new Date(a.modifiedDate).getTime()
      );
  }

  public get subnetIds(): FormArray {
    return this.createProxyEndpointForm.get('subnetIds') as FormArray;
  }

  public checkDuplicateSubnetId(
    formGroup: FormGroup
  ): { [key: string]: boolean } | null {
    // We always set the error for duplicate subnet ids on the second field so its easier to manage
    if (
      formGroup.get('subnetIds.0')?.value &&
      formGroup.get('subnetIds.0')?.value ===
        formGroup.get('subnetIds.1')?.value
    ) {
      formGroup.get('subnetIds.1')?.setErrors({ duplicateSubnetId: true });
    } else {
      if (formGroup.get('subnetIds.1')?.hasError('duplicateSubnetId'))
        formGroup.get('subnetIds.1')?.setErrors(null);
    }

    return null;
  }

  public checkIfProxyExistsForCluster(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const inputValue = control.value.toString();
    if (inputValue) {
      const keys = Object.keys(this.data.selectedCluster.proxyEndpoints);
      if (keys && keys.includes(inputValue)) {
        return { proxyExistsForCluster: true };
      }
    }
    return null;
  }

  public createEndpoint(): void {
    if (this.createProxyEndpointForm.valid) {
      this.disableConfirmShowLoader = true;
      this.store
        .dispatch(new CreateProxyEndpoint(this.createProxyEndpointForm.value))
        .pipe(
          withLatestFrom(this.rdsClusterState$),
          catchError((err) => {
            this.alertService.error({
              body: err.error.message,
            });
            this.dialogRef.close(this.createProxyEndpointForm.value);
            this.disableConfirmShowLoader = false;
            return throwError(() => new Error(''));
          })
        )
        .subscribe(([_, res]) => {
          const awsAccountName = this.enabledCrossAccounts.filter(
            (account) =>
              account.attributeId ===
              this.createProxyEndpointForm.value['attributeId']
          )[0];
          if (res.commonResponse.status_code <= 202) {
            this.alertService.success({
              body: `The RDS proxy endpoint is being created for RDS cluster &nbsp;<b>${this.data.selectedCluster.clusterName}</b>&nbsp; for Project AWS account &nbsp;<b>${awsAccountName['accountId']}</b>.&nbsp; It can take upto 10 minutes to complete. Click on Actions > Connection details to access the proxy endpoint information.`,
              autoDismiss: false,
            });
          } else {
            this.alertService.error({
              body: `RDS Proxy endpoint creation failed with the following error : ${res.commonResponse.message}`,
            });
          }
          this.disableConfirmShowLoader = false;
          this.dialogRef.close(this.createProxyEndpointForm.value);
        });
    }
  }

  public onCancel(): void {
    this.dialogRef.close(null);
  }
}
