<div class="mpr-mat-dialog-container container-size">
  <h2>Create new restricted data use group</h2>
  <form
    [formGroup]="restrictedGroupForm"
    (ngSubmit)="submit()"
    class="mpr-dialog-form"
  >
    <mat-dialog-content>
      <mat-form-field
        
        class="mpr-mat-input-field mpr-width-percentage-100"
        [hideRequiredMarker]="true"
      >
        <input aria-label="Project Restricted Data Use Groups"
          type="text"
          id="projectRestrictedDataUseGroups"
          formControlName="projectRestrictedDataUseGroups"
          matInput
          autocomplete="off"
          class="mpr-metadata-form-block-field-input"
          maxlength="20"
        />
      </mat-form-field>
      <mat-error
        class="d-flex mpr-margin-top"
        *ngIf="restrictedGroupForm.controls['projectRestrictedDataUseGroups'].dirty  && restrictedGroupForm.controls['projectRestrictedDataUseGroups'].errors?.['pattern']"
        >Restricted data group must start with alphabets or numbers and it can
        contain only alphabets, numbers, dot(.), hyphen(-) and
        underscore(_).</mat-error
      >
      <mat-error
        class="d-flex mpr-margin-top"
        *ngIf="restrictedGroupForm.controls['projectRestrictedDataUseGroups'].dirty && restrictedGroupForm.controls['projectRestrictedDataUseGroups'].errors?.['textValidators']"
      >
        {{restrictedGroupForm.controls['projectRestrictedDataUseGroups'].errors?.['message']}}
      </mat-error>
    </mat-dialog-content>
    <mat-dialog-actions>
      <div class="mpr-action-container">
        <div class="mpr-buttons-row" fxLayout="row" fxLayoutAlign="flex-end">
          <div>
            <button
              mat-button
              mat-dialog-close
              class="mpr-button mpr-blue-button mpr-cancel-button"
            >
              CANCEL
            </button>
          </div>
          <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
          <div>
            <button
              mat-button
              class="mpr-button mpr-yellow-button"
              [disabled]="!restrictedGroupForm.valid"
              type="submit"
            >
              <span>CONFIRM</span>
            </button>
          </div>
        </div>
      </div>
    </mat-dialog-actions>
  </form>
</div>
