import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UserProjectRoleEnum, userStatus } from '@core';
import { USER_STATUS } from '@core/constants';
import { HeaderParams, MprHttpHeaderModal } from '@core/interfaces';
import { AlertMessageService } from '@core/services';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { CommonResponseModel } from '@shared';
import { User, UserProject } from '@theme/interfaces';
import {
  DomainListState,
  FeatureFlagsState,
  GetAllowedDomains,
  LoadSelectedProjectMetadata,
  ProjectState,
  ResetSelectedProjectMetadata,
  UserProjectState,
} from 'app/state';
import { FeatureFlagsStateModel } from 'app/state/feature-flags';
import { ProjectUserList } from 'app/state/project-user/project-user-list-model';
import {
  EditProjectUser,
  GetUserDetails,
  ResetEditUserState,
} from 'app/state/project-user/project-user.actions';
import { ProjectUserState } from 'app/state/project-user/project-user.state';
import {
  ResetpdateEc2InstanceType,
  UpdateEc2InstanceType,
  UpdateInstanceState,
} from 'app/state/update-instance';
import { catchError, Observable, throwError, timer } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mpr-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit, OnDestroy {
  @Select(DomainListState.returnAllowedDomains)
  public allowedDomains$?: Observable<string[]>;
  @Select(UserProjectState.getSelectedProject)
  public project$?: Observable<UserProject>;
  @Select(ProjectUserState.getLoaderStatus)
  public showSpinner$?: Observable<boolean>;
  @Select(UpdateInstanceState.returnUpdatedEc2InstanceType)
  public updatedEc2Status?: Observable<CommonResponseModel>;
  @Select(ProjectUserState.returnUsersDetails)
  public userDetails$?: Observable<ProjectUserList>;
  @Select(ProjectState.getProjectPowerUsers)
  private projectPoweruser$!: Observable<boolean>;
  public allRestrictedGroupsForProject: string[] = [];
  public allowedExternalDomains: Array<string> = [];
  public canChangeBedrockAccessStatus = true;
  public canChangePowerUserStatus = true;
  public config: ProjectUserList;
  public featureFlags!: FeatureFlagsStateModel;
  public isPlatformAdminUserEdit = false;
  public isPowerUserApplicable = false;
  public isRestrictedCheckboxDataChange = false;
  public returnPath: string;
  public selectedProjectId = '';
  public selectedUserDetails!: User;

  private requestHeaders?: MprHttpHeaderModal = {};
  private userId: string;

  constructor(
    private route: ActivatedRoute,
    public store: Store,
    private router: Router,
    public dialog: MatDialog,
    private alertMsgService: AlertMessageService
  ) {
    this.userId = atob(route.snapshot.params['userId']);
    const encodedProjectId = route.snapshot.params['projectId'];
    if (encodedProjectId) {
      this.selectedProjectId = atob(encodedProjectId);
      this.isPlatformAdminUserEdit = true;
    }
    this.returnPath = route.snapshot.data['back'];

    this.config = {
      amazonWorkspace: {},
      ec2Status: '',
      emailId: '',
      firstName: '',
      isPowerUser: false,
      lastName: '',
      machineSize: '',
      projectId: this.selectedProjectId,
      projectRestrictedDataUseGroups: [],
      roleName: '',
      userId: '',
      userStatus: '',
      powerUserFeatures : {
        isHLBedrockAccess : false
      }
    };
  }
  public checKFormValue(userForm: NgForm): boolean {
    if (!userForm.form.pristine) {
      return false;
    } else if (!this.isRestrictedCheckboxDataChange) {
      return false;
    } else {
      return true;
    }
  }

  public checkAWSWorkspaceSectionDisplay(): boolean {
    return this.config.amazonWorkspace &&
      this.featureFlags.isAwsWorkspacesAvailable === 'True'
      ? this.isPlatformAdminUserEdit
      : false;
  }

  public checkRoleLable(): boolean {
    return this.config.ec2Status
      ? this.isPlatformAdminUserEdit &&
          !(
            this.config.roleName === UserProjectRoleEnum.GENERAL_RESEARCHER ||
            this.config.roleName === UserProjectRoleEnum.RESEARCHER ||
            this.config.roleName === UserProjectRoleEnum.EXTERNAL_RESEARCHER
          )
      : false;
  }

  public navigateBack(message?: any): void {
    if (this.isPlatformAdminUserEdit) {
      const encodedEmailId = btoa(this.config.emailId);
      this.returnPath = `/platformAdmin/users/manage/${encodedEmailId}`;

      if (this.router.url.includes('platformAdmin/user/edit')) {
        this.returnPath = `/platformAdmin/users/list/${this.route.snapshot.params['projectId']}`;
      }
    }

    this.router.navigate([this.returnPath]);
  }
  ngOnDestroy(): void {
    this.store.dispatch(new ResetEditUserState());
    this.store.dispatch(new ResetSelectedProjectMetadata());
    this.store.dispatch(new ResetpdateEc2InstanceType());
    this.dialog.closeAll();
  }

  ngOnInit(): void {
    this.featureFlags = this.store.selectSnapshot(
      FeatureFlagsState.returnFeatureFlags
    );
    if (this.isPlatformAdminUserEdit) {
      this.requestHeaders = {};
      this.requestHeaders[HeaderParams.PROJECTID] = this.selectedProjectId;
      this.requestHeaders[HeaderParams.ROLENAME] =
        UserProjectRoleEnum.PLATFORM_ADMIN;
      this.store
        .dispatch(new LoadSelectedProjectMetadata(this.requestHeaders))
        .subscribe(() => {
          this.allRestrictedGroupsForProject = this.store.selectSnapshot(
            ProjectState.getProjectRestrictedDataGroups
          );
          this.isPowerUserApplicable = this.store.selectSnapshot(
            ProjectState.getProjectPowerUsers
          );
        });
    } else {
      delete this.requestHeaders;
    }
    this.store.dispatch(new GetUserDetails(this.userId, this.requestHeaders));
    const loggedInUserRole = this.isPlatformAdminUserEdit
      ? UserProjectRoleEnum.PLATFORM_ADMIN
      : UserProjectRoleEnum.ADMIN;
    if (!this.selectedProjectId) {
      this.project$?.subscribe((project: UserProject) => {
        this.selectedProjectId = project.projectId;
        this.allRestrictedGroupsForProject =
          project.userRestrictedDataUseGroups;
          this.store.dispatch(
            new GetAllowedDomains(this.selectedProjectId, loggedInUserRole)
          );
      });
      this.projectPoweruser$?.subscribe((poweruser: boolean) => {
        this.isPowerUserApplicable = poweruser;
      });
    } else {
      this.store.dispatch(
        new GetAllowedDomains(this.selectedProjectId, loggedInUserRole)
      );
    } 
    this.userDetails$?.subscribe((userDetails: ProjectUserList) => {
      userDetails.powerUserFeatures = userDetails.powerUserFeatures || {}
      userDetails.powerUserFeatures['isHLBedrockAccess'] = userDetails.powerUserFeatures && userDetails.powerUserFeatures['isHLBedrockAccess'] || false;
      this.config = { ...userDetails };
      // If BE returns the disabled status then UI can navigate the user back.
      if (this.config.userStatus === USER_STATUS.DISABLED) this.navigateBack();
      if (userDetails.isPowerUser) this.canChangePowerUserStatus = false;
      if ((userDetails.powerUserFeatures && userDetails.powerUserFeatures.isHLBedrockAccess) || !this.isPlatformAdminUserEdit) this.canChangeBedrockAccessStatus = false;
    });
    this.allowedDomains$?.subscribe((externalDomains: Array<string>) => {
      this.allowedExternalDomains = externalDomains;
    });
  }

  public onSubmit(): void {
    const id =
      this.config.userStatus === userStatus.INVITED
        ? this.config.emailId
        : this.config.userId;
    this.store
      .dispatch(new EditProjectUser(this.config, this.requestHeaders))
      .pipe(
        catchError((err) => {
          this.store.dispatch(new GetUserDetails(id, this.requestHeaders));
          this.alertMsgService.error({
            body: err.error.message,
          });
          return throwError(() => new Error(''));
        })
      )
      .subscribe(() => {
        this.userDetails$?.subscribe((userDetails: ProjectUserList) => {
          this.config = { ...userDetails };
        });
        setTimeout(() => {
          this.alertMsgService.success({
            body: 'User information updated successfully',
            autoDismiss: false,
          });
        }, 0);

        if (!this.isPlatformAdminUserEdit) {
          timer(1000).subscribe(() => {
            this.navigateBack();
          });
        }
      });
  }

  public restrictedCheckboxDataChange($event: any): void {
    this.isRestrictedCheckboxDataChange = $event;
  }

  public updateEventHander(event$: any): void {
    this.store.dispatch(new ResetpdateEc2InstanceType());
    this.store.dispatch(
      new UpdateEc2InstanceType(
        event$.data.machineSize,
        event$.data.userId,
        event$.data.projectId,
        event$.requestHeaders
      )
    );
    this.updatedEc2Status
      ?.pipe(
        catchError((err) => {
          this.alertMsgService.error({
            body: err.error.message,
          });
          return throwError(() => new Error(''));
        })
      )
      .subscribe((ec2: CommonResponseModel) => {
        if (ec2 && ec2.status_code) {
          if (ec2?.status_code === 200) {
            this.alertMsgService.success({
              body: `EC2 instance type for ${event$.data['firstName']} ${event$.data['lastName']} has been updated to  ${event$.data['machineSize']}`,
            });
            this.store.dispatch(
              new GetUserDetails(this.userId, this.requestHeaders)
            );
          }
          if (ec2?.status_code === 400) {
            this.alertMsgService.error({
              body: ec2.message,
            });
          }
        }
      });
  }
}
