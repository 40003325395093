import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { noWhitespaceValidator } from '@shared/validators';
import { Store } from '@ngxs/store';
import { ManageRDSClusterState } from 'app/state';
@Component({
  selector: 'app-add-edit-rds',
  templateUrl: './add-edit-rds.component.html',
  styleUrls: ['./add-edit-rds.component.scss'],
})
export class AddEditRDSComponent {
  public existingClusterNames: string[];
  public isEditCluster: boolean;
  public setupRDSForm: FormGroup;
  constructor(
    private dialogRef: MatDialogRef<AddEditRDSComponent>,
    private fb: FormBuilder,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.existingClusterNames = this.store.selectSnapshot(
      ManageRDSClusterState.getExistingClusterNames
    );
    this.isEditCluster = data.isEditCluster;
    this.setupRDSForm = this.fb.group({
      projectName: [{ value: data.projectName, disabled: true }],
      clusterName: [
        data.clusterName,
        [
          Validators.required,
          Validators.maxLength(100),
          this.uniqueClusterNameValidator.bind(this),
          noWhitespaceValidator(),
        ],
      ],
      dbEngine: [{ value: data.dbEngine, disabled: true }],
      capacity: [{ value: data.capacity, disabled: true }],
    });
  }

  public onCancel(): void {
    this.dialogRef.close(null);
  }

  public onSave(): void {
    if (this.setupRDSForm.valid) {
      this.dialogRef.close(this.setupRDSForm.value);
    }
  }

  private uniqueClusterNameValidator(
    control: any
  ): { [key: string]: boolean } | null {
    if (this.existingClusterNames.includes(control.value.toLowerCase())) {
      return { uniqueClusterName: true };
    }
    return null;
  }
}
