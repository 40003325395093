<div>
  <form [formGroup]="reconfigureForm">
    <div class="mpr-form-block-field-label">EC2 Instance</div>
    <div class="d-flex">
      <div
        class="mpr-workpsace-status"
        [ngClass]="displayStatusColor(userEC2Status)"
      >
        {{ userEC2Status }}
      </div>
      <div *ngIf="userEC2Status !== 'stopped'">
        <b> NOTE: </b>The EC2 instance can be resized once it is stopped.
      </div>
    </div>
    <div class="mpr-form-fields mpr-padding-top" *ngIf="action === 'edit'">
      <div class="mpr-width-50">
        <mat-form-field
          class="mpr-mat-select-field mpr-select-field-width"
          appearance="outline"
          aria-label="Select EC2 Instance"
        >
          <mat-select
            id="ec2Status"
            formControlName="ec2Status"
            placeholder="Select EC2 Instance"
            (selectionChange)="changeEc2Instanace($event.value)"
            [disabled]="userEC2Status !== 'stopped'"
          >
            <mat-option
              *ngFor="let item of ec2InstanceTypes"
              [value]="item.value"
            >
              <span *ngIf="item.category">{{ item.category }} -</span>
              <span *ngIf="item.value"> {{ item.value }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div
          class="invalid-feedback"
          *ngIf="reconfigureForm.controls['ec2Status'].dirty  && reconfigureForm.controls['ec2Status'].errors?.['duplicateEc2Instance']"
        >
          The EC2 instance type is same as the current one. <br />
          Please try again with correct instance type.
        </div>
        <button
          class="mpr-btn-transparency mpr-cursor-pointer mpr-link mpr-stop-ec2-button"
          *ngIf="userEC2Status === 'running' || userEC2Status === 'stopping'"
          (click)="stopEC2InstanceConfirmPopup()"
          [disabled]="userEC2Status === 'stopping'"
          [ngClass]="userEC2Status === 'stopping' ? 'mpr-disabled' : ''"
        >
          <mat-icon style="color: #046b5b">power_settings_new</mat-icon>
          <span class="mpr-stop-ec2-button-text"> Stop Ec2 Instance </span>
        </button>
      </div>
      <div
        class="mpr-form-block-field mpr-width-50"
        *ngIf="isEnterInstanceType"
      >
        <input
          id="ec2InstanceValue"
          formControlName="ec2InstanceValue"
          type="text"
          class="mpr-emailFields"
          autocomplete="off"
          placeholder="Enter EC2 Instance type"
          maxlength="30"
        />

        <div
          class="mpr-wordcount d-flex"
          [ngClass]="
            reconfigureForm.controls['ec2InstanceValue'].value.length === 30
              ? 'mpr-error-color'
              : ''
          "
        >
          {{ reconfigureForm.controls['ec2InstanceValue'].value.length }}/30
          characters
        </div>

        <div
          *ngIf="
            (reconfigureForm.controls['ec2InstanceValue'].dirty ||
              reconfigureForm.controls['ec2InstanceValue'].touched) &&
            reconfigureForm.controls['ec2InstanceValue'].errors
          "
        >
          <div
            *ngIf="reconfigureForm.controls['ec2InstanceValue'].dirty &&reconfigureForm.controls['ec2InstanceValue'].errors?.['required']"
            class="invalid-feedback"
          >
            EC2 instance type is required.
          </div>
          <div
            *ngIf="!reconfigureForm.controls['ec2InstanceValue'].errors?.['required'] && reconfigureForm.controls['ec2InstanceValue'].errors?.['duplicateEc2Instance']"
            class="invalid-feedback"
          >
            The EC2 instance type is same as the current one. <br />
            Please try again with correct instance type.
          </div>
          <div
            *ngIf="!reconfigureForm.controls['ec2InstanceValue'].errors?.['required'] && reconfigureForm.controls['ec2InstanceValue'].errors?.['whitespace']"
            class="invalid-feedback"
          >
            EC2 instance type is invalid
          </div>
        </div>
      </div>
    </div>
    <div class="mpr-actions">
      <div></div>
      <div>
        <button
          class="mpr-submit-user"
          (click)="updateEc2instance()"
          mat-button
          [ngClass]="
            checKFormValue(reconfigureForm) || !reconfigureForm.valid
              ? 'mpr-disabled'
              : ''
          "
        >
          Update Ec2 Instance
        </button>
      </div>
    </div>
  </form>
</div>
