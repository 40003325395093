<div>
    <div fxLayout="column" class="mpr-mat-dialog-small">
        <h1 mat-dialog-title>{{ data.mappingAction }} Mapping</h1>

        <div mat-dialog-content>
            <form [formGroup]="mappingForm" 
                (ngSubmit)="onCreateMappingClick()">
                <div class="mpr-survey-details-wrapper d-flex">
                    <div class="mpr-form-fields-row-items mpr-survey-id">
                        <div class="mpr-form-block-field-label no-text-transform">Survey Id
                            <button class="mpr-btn-transparency" 
                                [mtxTooltip]="surveyIdInfo" 
                                [mprMtxTooltipAriaDescribedBy]="surveyIdInfo"
                                #tooltip="mtxTooltip"
                                aria-label="Survey Id Information"
                                type="button"
                            >
                                <mat-icon
                                    class="mpr-info-icon mpr-mapping-info"
                                    >info
                                </mat-icon>
                            </button>
                        </div>
                        <mat-form-field
                            class="mpr-mat-select-field mpr-width-percentage-100"
                            appearance="outline"
                        >
                            <input
                            id="surveyId" 
                            class="mpr-mat-input-field"
                            matInput
                            placeholder="Enter survey id"
                            formControlName="surveyId"
                            required
                            aria-label="survey id"
                            />
                            <mat-error *ngIf="mappingForm.get('surveyId')?.hasError('required')">
                                Survey Id is required.
                            </mat-error>
                            <mat-error *ngIf="mappingForm.get('surveyId')?.hasError('pattern')">
                                Survey Id can only contain letters and numbers.
                            </mat-error>
                            <mat-error *ngIf="mappingForm.get('surveyId')?.hasError('surveyIdExists')">
                                Survey ID already exists.
                           </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="mpr-form-fields-row-items">
                        <div class="mpr-form-block-field-label no-text-transform">Survey Name
                            <button class="mpr-btn-transparency" 
                                [mtxTooltip]="surveyNameInfo" 
                                [mprMtxTooltipAriaDescribedBy]="surveyNameInfo"
                                #tooltip="mtxTooltip"
                                aria-label="Survey Name Information"
                                type="button"
                            >
                                <mat-icon
                                    class="mpr-info-icon mpr-mapping-info"
                                    >info
                                </mat-icon>
                            </button>
                        </div>
                        <mat-form-field
                            class="mpr-mat-select-field mpr-width-percentage-100"
                            appearance="outline">
                        <input
                            id="surveyName"
                            class="mpr-mat-input-field"
                            matInput
                            placeholder="Enter survey name"
                            formControlName="surveyName"
                            required
                            aria-label="survey Name"
                            />
                            <mat-error *ngIf="mappingForm.get('surveyName')?.invalid && (mappingForm.get('surveyName')?.dirty || mappingForm.get('surveyName')?.touched)">
                                Survey Name is required.
                            </mat-error>
                        </mat-form-field>
                    </div>
                
                </div>
            </form>
        </div>
        <div class="mpr-progressbar-actions" mat-dialog-actions>
            <div>
                <button
                    mat-stroked-button
                    mat-dialog-close
                    type="button"
                    class="mpr-button mpr-blue-button mpr-cancel-button"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    mat-button
                    class="mpr-button mpr-yellow-button mpr-button-authorize"
                    [disabled]="mappingForm.invalid"
                    (click)="onCreateMappingClick()"
                    *ngIf = "data.mappingAction === 'Create'"
                >
                Create Mapping
                </button>
                <button
                    type="submit"
                    mat-button
                    class="mpr-button mpr-yellow-button mpr-button-authorize"
                    [disabled]="mappingForm.invalid || isValueSameAsPreviousMapping()"
                    (click)="confirmEditMapping()"
                    *ngIf = "data.mappingAction === 'Edit'"
                >
                Confirm
                </button>
            </div>
        </div>
    </div>
</div>

<ng-template #surveyIdInfo>
    <div class="mpr-width-px-300" id="mpr-restricted-groups">
        The Forsta project ID (PID) that is used to identify the survey in Forsta.
    </div>
</ng-template>

<ng-template #surveyNameInfo>
    <div class="mpr-width-px-300" id="mpr-restricted-groups">
        The name of the specific data collection instrument associated with the Survey.
    </div>
</ng-template>