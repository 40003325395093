<div class="mpr-mat-dialog-confirm mpr-mat-dialog-small container-size mpr-cred-dialog-container">
  <div class="mpr-r-close" (click)="closeDialog()">
    <img src="assets/images/cancel-popup.svg" alt="Close icon" />
  </div>
  <h1 mat-dialog-title class="mpr-r-title">Select R Version</h1>
  <div>
    <div class="mpr-margin-bottom">Select the R version you would like to use from R Studio</div>
    <mat-form-field
      
      class="mpr-mat-select-field mpr-dropdown-width"
      [hideRequiredMarker]="true"
      appearance="outline"
    >
      <mat-select 
        id ="SelectRVersion"
        class="mpr-form-block-field-input"
        matInput
        placeholder="Select R version"
        #format = "ngModel"
        panelClass="mpr-select-option-panel mpr-mat-arrow"
        [(ngModel)]="selectedVersion"
      >
        <mat-option *ngFor="let version of displayRversionValues" [value]="version">{{ version }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="mpr-buttons-row mpr-margin-top" fxLayout="row" fxLayoutAlign="flex-end">
    <div>
      <button mat-stroked-button class="mpr-button mpr-blue-button mpr-cancel-button" (click)="closeDialog()">Cancel</button>
    </div>
    <div>
      <button mat-button class="mpr-button mpr-yellow-button" [disabled]="!selectedVersion || disableProceed" (click)="updateRVersion(selectedVersion)">Proceed</button>
    </div>
  </div>
</div>