import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@env/environment';
import { SkipMainContentService } from '@shared/services';
import { filter, Subject, takeUntil, Observable } from 'rxjs';
import { FeatureFlagsState, LoggedinUserState } from 'app/state';
import { FeatureFlagsStateModel } from 'app/state/feature-flags';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  @HostBinding('class') public class = 'matero-header';
  @Select(FeatureFlagsState.returnFeatureFlags)
  public featureFlags$!: Observable<FeatureFlagsStateModel>;
  @Input() public showToggle = true;
  @Output() public toggleSidenav = new EventEmitter<void>();
  @Output() public toggleSidenavNotice = new EventEmitter<void>();
  public externalTermsOfUseURL = environment.externalTermsOfUsePageURL;
  public helpPageUrl = environment.helpPageNowUrl;
  public loggedinUserEmailDomain = '';
  public termsOfUse = '';
  public termsOfUseUrl = environment.termsOfUsePageURL;

  private destroyed$ = new Subject<boolean>();
  constructor(
    private store: Store,
    private router: Router,
    private skipMainContentService: SkipMainContentService
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroyed$)
      )
      .subscribe((e) => {
        setTimeout(() => {
          document.getElementById('setFocusField')?.focus();
        }, 0);
      });
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public panelOpenState = false;
  @HostListener('document:click', ['$event'])
  public clickOutSide(event: Event): void {
    const className = (event.target as HTMLInputElement).className;
    if (
      this.panelOpenState &&
      !className.includes('mat-expansion-indicator') &&
      !className.includes('matero-username')
    ) {
      this.panelOpenState = false;
    }
    if (
      typeof className === 'string' &&
      className.includes('matero-username')
    ) {
      this.panelOpenState = !this.panelOpenState;
    }
  }
  public skipToMainContent(): void {
    this.skipMainContentService.skiptomain();
  }

  ngOnInit(): void {
    this.featureFlags$.subscribe((featureFlags: FeatureFlagsStateModel) => {
      this.loggedinUserEmailDomain = this.store
        .selectSnapshot(LoggedinUserState.getLoggedInUserEmail)
        .split('@')[1];
      const isLoggedInUserInternal =
        featureFlags.internalDomainList.findIndex(
          (x) => x === this.loggedinUserEmailDomain
        ) > -1;
      this.termsOfUse = isLoggedInUserInternal
        ? this.termsOfUseUrl
        : this.externalTermsOfUseURL;
    });
  }
}
