<div fxLayout="column" class="mpr-mat-dialog-small">
    <h1 mat-dialog-title>{{ title }}</h1>
    <div mat-dialog-content>
        {{ description }}
    </div>
    <div class="mpr-progressbar-actions" mat-dialog-actions fxLayout="row" fxLayoutAlign="flex-end center"
        *ngIf="!scheduleCreationPending">
        <a *ngIf="scheduleCreated" (click)="navigateToRecurringSchedule()" mat-dialog-close
            class="mpr-mat-dialog-action-link text-uppercase">View Manage Schedules</a>
        <button mat-button class="mpr-button mpr-yellow-button" routerLink="/dashboard" mat-dialog-close>
            Go Back Home
        </button>
    </div>
</div>